import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import { ResponsiveWidthContainer, SectionTitle } from "../components/Section";
import { Button } from "../components/Buttons";
import BlogPreview from "../components/BlogPreview";

const articleListStyle = `
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const CenterWrapper = styled.div`
  text-align: center;
`;

// const buttonStyle = `
//     color: currentColor;
//     display: block;
//     margin: 2rem auto;
//     background-color: transparent;
//     border: 1px solid currentColor;
//     border-radius: 10px;
//     padding: 0.6rem 1.5rem;
//     cursor: pointer;
//     transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
//     &:hover {
//       background-color: #000;
//       border-color: #000;
//       color: #fff;
//     }
//   `;

export const BlogPageTemplate = ({ title, description, posts, location }) => {
  const initialPostNum = 9;
  const [numPosts, setNumPosts] = useState(initialPostNum);
  return (
    <ResponsiveWidthContainer>
      {title && <SectionTitle leftAlign>{title}</SectionTitle>}
      {description && <p>{description}</p>}
      <div css={articleListStyle}>
        {posts.slice(0, numPosts).map(({ node: post }) => (
          <BlogPreview key={post.id} post={post} location={location} />
        ))}
      </div>
      {numPosts < posts.length && (
        <CenterWrapper>
          <Button
            outline
            color="basic"
            onClick={() => setNumPosts(numPosts + initialPostNum)}
          >
            Load more
          </Button>
        </CenterWrapper>
      )}
    </ResponsiveWidthContainer>
  );
};

BlogPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  posts: PropTypes.array,
  location: PropTypes.object
};

const BlogPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout location={location}>
      <BlogPageTemplate {...frontmatter} posts={posts} location={location} />
    </Layout>
  );
};

BlogPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  }),
  location: PropTypes.object
};

export default BlogPage;

export const blogPageQuery = graphql`
  query BlogPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post-page" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 150)
          id
          fields {
            slug
          }
          frontmatter {
            title
            author
            alt
            featureimage {
              childImageSharp {
                fluid(maxWidth: 370, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
          }
        }
      }
    }
  }
`;
