import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { distanceInWordsToNow } from "date-fns";
import { FiClock } from "react-icons/fi";

const articleWrapperStyle = `
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem
  flex-basis: 100%;  
  @media (min-width: 480px) {
    flex-basis: 100%;
  }
  @media (min-width: 720px) {
    flex-basis: 33.33%;
  }   
`;

const articleStyle = `
  border-radius: 2px;
  border: 1px solid rgba(176, 182, 190, 0.2);
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 1px 0px 1px 0px rgba(176, 182, 190, 0.2);
`;

const textStyle = `
  padding: 0.5rem 1.5rem;
  color: #B0B6BE;
  font-weight: bold;
  line-height: 1.8rem;
  border-bottom: 2px solid rgba(176, 182, 190, 0.2);
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;

  a, a:visited {
    color: #15ADE1;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a, h3 {
    flex-grow: 1;
  }

  blockquote {
    line-height: 1.2rem;
    font-size: 0.7rem;
    margin-top: 0;
    margin: 0;
    margin-bottom: 3rem;
    padding: 0;
  }  
`;

const dateStyle = `
  border: none;
  color: #B0B6BE;
  padding: 1.5rem 1.5rem;
  font-size: 0.7rem;
  font-weight: bold;
`;

const imageStyle = `
  box-shadow: 1px 0px rgba(176, 182, 190, 0.2);
  border-radius: 2px;
`;

const BlogPreview = ({ post, location }) => {
  return (
    <div css={articleWrapperStyle}>
      <article css={articleStyle} key={post.id}>
        <Image
          css={imageStyle}
          fluid={post.frontmatter.featureimage.childImageSharp.fluid}
          alt={post.frontmatter.alt}
        />
        <div css={textStyle}>
          <Link to={post.fields.slug}>
            <h3>{post.frontmatter.title}</h3>
          </Link>
          <blockquote cite={`${location.origin}${post.fields.slug}`}>
            {" "}
            {post.excerpt}
          </blockquote>
        </div>
        <footer css={dateStyle}>
          <FiClock
            css={`
              margin-right: 7px;
              margin-bottom: 1px;
            `}
          />
          <time dateTime={post.frontmatter.date}>{`${distanceInWordsToNow(
            post.frontmatter.date
          )} ago`}</time>
        </footer>
      </article>
    </div>
  );
};

BlogPreview.propTypes = {
  post: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default BlogPreview;
